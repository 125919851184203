import Loader from "../Loader";
import { ImCheckmark } from "react-icons/im";

export default function TestStatusIcon(props: { status: string }) {
    switch (props.status) {
        case "running":
            return <Loader />
        case "finished":
            return <ImCheckmark />
        default:
            return null;
    }
}