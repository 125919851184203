import { useParams } from "react-router-dom";
import Terminal from "../components/atoms/Terminal";

export default function ServiceConsole() {
  let params = useParams();
  return (
    <Terminal
      url={"/service/" + params.servId + "/console"}
      autofocus
      enableWrite={true}
      closeAuto={true}
      fullscreen={true}
      snackNotifications={true}
    />
  );
}
