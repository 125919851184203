import { Alert } from "@mui/material";

import { envTypes } from "types";
import { getTimeDeltaSince } from "utils";

const ActivityLogs = (env: envTypes.Environment) => {
  return (
    <div className="flex flex-col gap-s items-baseline w-full mt-m bg-white rounded p-s overflow-y-scroll overscroll-contain max-h-[50vh]">
      {env?.activityLogs?.length === 0 && (
        <p className="text-m  text-neutral col-span-3">No activity</p>
      )}
      {env?.activityLogs?.map((log: envTypes.ActivityLog) => (
        <Log {...{ ...log, createdAt: env.createdAt }} />
      ))}
    </div>
  );
};

const Log = (log: envTypes.ActivityLog & { createdAt?: Date }) => {
  const isSuccess =
    log.message.toLowerCase().includes("success") ||
    log.message.toLowerCase().includes("successfully");
  const isError = !!log.error;

  const severity = isSuccess ? "success" : isError ? "error" : "info";
  return (
    <Alert severity={severity} className="w-full">
      {log.message}
      {log.details && <p className="text-s">{log.details}</p>}
      {isError && <p className="text-s text-error">{log.error}</p>}
      {log.createdAt && log.when && (
        <p className="text-s text-neutral pb-0 ml-auto mt-xs">
          +{getTimeDeltaSince(new Date(log.when), new Date(log.createdAt))}
        </p>
      )}
    </Alert>
  );
};

export default ActivityLogs;
