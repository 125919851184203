import Axios from "axios";
import Cookies from "js-cookie";
import { DefaultOptions, QueryClientConfig } from "react-query";

const localHostnames = ["panel.localhost.lan", "localhost", "127.0.0.1", "0.0.0.0", "panel.localtest.me","panel.local.qaack.cloud"];
export const isDevEnv = localHostnames.includes(window.location.hostname);


export const BASE_URL =
  (isDevEnv ? "http" : "https") +
  "://" +
  window.location.hostname +
  (isDevEnv ? ":" + window.location.port : "");

export const BASE_WS_URL =
  (isDevEnv ? "ws" : "wss") +
  "://" +
  window.location.hostname +
  (isDevEnv ? ":" + window.location.port : "");

export const API_URL = BASE_URL + "/api";
export const API_WS_URL = BASE_WS_URL + "/api";
export const EVENTS_WS_URL = API_WS_URL + "/events/ws";

// Axios
export const axiosInstance = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Session interceptor
axiosInstance.interceptors.request.use((config) => {
  const token = Cookies.get("token");
  if (token && config.headers) {
    config.headers["X-QAACK-TOKEN"] = token;
  }
  return config;
});

// Timezone interceptor, passes the timezone hours diff
axiosInstance.interceptors.request.use((config) => {
  const timezone = new Date().getTimezoneOffset() / 60;
  if (config.headers) {
    config.headers.timezone = timezone;
  }
  return config;
});

// Local language interceptor
axiosInstance.interceptors.request.use((config) => {
  const language = "es-es";
  if (config.headers) {
    config.headers["Accept-Language"] = language;
  }
  return config;
});

// 404 interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 404) {
     // window.location.href = "/404";
    }
    return Promise.reject(error);
  }
);

// React Query
export const queryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  } as DefaultOptions,
};
