import { ReactNode, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Group, LinkOutlined, Person } from "@mui/icons-material";

import { envTypes, groupTypes, repoTypes, userTypes } from "types";
import { useLocalStorage, UserState } from "store";
import { environmentsService } from "services";

import { EnvOption, GroupOption, RepoOption } from "components/molecules";
import NormalPage from "./layout/page";
import { Alert } from "@mui/material";

const WelcomeActionItem = (props: {
  icon: ReactNode;
  title: string;
  action?: Function;
  link?: string;
}) => {
  const classes = "flex gap-s items-center hover:bg-brand/10 py-xs px-m";
  const titleClasses = "text-m";
  if (props.action)
    return (
      <button onClick={() => props.action?.()} className={classes}>
        {props.icon}
        <p className={titleClasses}>{props.title}</p>
      </button>
    );

  if (props.link)
    return (
      <a href={props.link} className={classes} target="_blank" rel="noreferrer">
        {props.icon}
        <p className={titleClasses}>{props.title}</p>
      </a>
    );

  return null;
};

const WelcomeSection = (props: { title: string; content: ReactNode[] }) => {
  return (
    <div className="flex flex-col bg-white rounded py-s shadow-sm">
      <p className="text-neutral text-l font-bold bg-clip-text text-transparent bg-gradient-to-r to-brand-light from-brand py-xs px-m">
        {props.title}
      </p>
      {!props.content.length && <Alert severity="info">No recent {props.title}</Alert>}
      {props.content}
    </div>
  );
};

const ListOfEntries = (props: {
  title: string;
  entries: userTypes.User["recentlyVisited"];
}) => {
  const entries = props.entries.map((item) => {
    switch (item.type) {
      case "group":
        return <GroupOption {...(item.data as groupTypes.Group)} />;
      case "repository":
        return <RepoOption {...(item.data as repoTypes.Repository)} />;
      case "environment":
        return <EnvOption {...(item.data as envTypes.Environment)} />;
      default:
        return null;
    }
  });

  return (
    <WelcomeSection
      title={props.title}
      content={entries}
    />
  );
};


const StartedWith = () => {
  const onSelectProfile = () =>
    document.getElementById("profile-button")?.click();
  const onSelectGroup = () => {
    (
      document.getElementById("group-select-label")
        ?.nextElementSibling as HTMLElement
    )?.click();
  };

  return (
    <WelcomeSection
      title="Get started with..."
      content={
        [
          <WelcomeActionItem
            icon={<Person className="text-brand" />}
            title="Checking your profile"
            action={onSelectProfile}
          />,
          <WelcomeActionItem
            icon={<Group className="text-brand" />}
            title="Selecting a group"
            action={onSelectGroup}
          />,
          <p className="text-neutral text-l font-bold bg-clip-text text-transparent bg-gradient-to-r to-brand-light from-brand py-xs px-m mt-m">
            Docs and examples
          </p>,
          <WelcomeActionItem
            icon={<LinkOutlined className="text-brand" />}
            title="Getting started"
            link="https://docs.qaack.cloud/user-guide/"
          />,
          <WelcomeActionItem
            icon={<LinkOutlined className="text-brand" />}
            title="API reference"
            link="https://docs.qaack.cloud/specifications/api/"
          />,
          <WelcomeActionItem
            icon={<LinkOutlined className="text-brand" />}
            title="Qaack docker images"
            link="https://gitlab.com/qaack/images"
          />,
          <WelcomeActionItem
            icon={<LinkOutlined className="text-brand" />}
            title="Showcases"
            link="https://gitlab.com/qaack/showcases"
          />,
          <WelcomeActionItem
            icon={<LinkOutlined className="text-brand" />}
            title="Qaack"
            link="https://qaack.cloud/"
          />,
          <WelcomeActionItem
            icon={<LinkOutlined className="text-brand" />}
            title="Blog"
            link="https://qaack.cloud/blog"
          />
        ]
      }
    ></WelcomeSection>
  );
};

export default function Welcome() {
  const uContext = useContext(UserState);
  const [recentlyVisited, setRecentlyVisited] = useLocalStorage(
    "recentlyVisited",
    []
  );
  const [favorites, setFavorites] = useLocalStorage("favorites", []);

  const { data: envs } = recentlyVisited.filter((item: any) => item.type === "environment").length > 0 ?
    environmentsService.useListOfEnvs(
      recentlyVisited.filter((item: any) => item.type === "environment")
        .map((item: any) => item.visitedId))
    : { data: undefined };

  useEffect(() => {
    const updatedRecentlyVisited = recentlyVisited.map((item: any) => {
      if (item.type === "environment" && !!envs) {
        const env = envs?.find((env) => env.id === item.visitedId);
        if (env) return { ...item, data: { ...item.data, ...env } };
      }
      return item;
    });
    const updatedFavorites = favorites.map((item: any) => {
      if (item.type === "environment" && !!envs) {
        const env = envs?.find((env) => env.id === item.visitedId);
        if (env) return { ...item, data: { ...item.data, ...env } };
      }
      return item;
    });

    if (
      JSON.stringify(updatedRecentlyVisited) !== JSON.stringify(recentlyVisited)
    )
      setRecentlyVisited(updatedRecentlyVisited);
    if (JSON.stringify(updatedFavorites) !== JSON.stringify(favorites))
      setFavorites(updatedFavorites);
  }, [envs, setRecentlyVisited, recentlyVisited, setFavorites, favorites]);

  return (
    <NormalPage>
      <p className="text-neutral text-xxl flex gap-s mb-m leading-10">
        Hi{" "}
        {uContext.userInfo.username && (
          <p className="flex">
            <Link
              className="text-xxl bg-clip-text text-transparent bg-gradient-to-r to-brand-light from-brand font-bold"
              to="/profile"
            >
              {uContext.userInfo.username}
            </Link>
            ,
          </p>
        )}{" "}
        welcome to Qaack!
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-m mt-xl">
        <ListOfEntries entries={favorites} title="Favorites" />
        <ListOfEntries entries={recentlyVisited} title="Recently visited" />
        <StartedWith />
      </div>
    </NormalPage>
  );
}
