import { useMemo, useState } from "react";
import { Alert, Modal, Tooltip } from "@mui/material";
import { Assessment } from "@mui/icons-material";

import { testTypes } from "types";
import { TestIcon, TestStatusIcon, Title } from "components/atoms";

import Teaser from "./baseTeaser";
import CircularProgressWithLabel from "../circularProgress";
import { DurationString } from "utils";

const ImageResult = (result: testTypes.TestResult & { testId: string }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const src = `/api/test/${result.testId}/result/${result.label}`;

  return (
    <div className="relative">
      <img
        src={src}
        alt={result.label}
        onClick={() => setFullScreen(!fullScreen)}
        className="!h-xl w-auto hover:cursor-pointer"
      />
      <Modal open={fullScreen} onClose={() => setFullScreen(false)}>
        <img
          src={src}
          alt={result.label}
          className="bg-white p-m rounded w-auto h-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </Modal>
    </div>
  );
};

const PercentageResult = (result: testTypes.TestResult) => {
  const percentage = Number(result.value);
  const color =
    percentage > 80 ? "primary" : percentage > 50 ? "warning" : "error";
  return (
    <CircularProgressWithLabel
      color={color}
      size={50}
      values={[
        {
          value: percentage,
          label: result.label,
        },
      ]}
      label={result.label}
    />
  );
};

const NumberResult = (result: testTypes.TestResult) => {
  const number = Number(result.value);
  return (
    <div className="flex flex-col items-center">
      <p className="text-neutral text-m">{number}</p>
      <p className="text-neutral text-m">{result.label}</p>
    </div>
  );
};

const PlaywrightResult = (result: testTypes.TestResult) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [passed, failed] = result.value.split("/").map(Number);

  return (
    <div className="relative">
      <div
        className="flex gap-s items-center cursor-pointer"
        onClick={() => setFullScreen(!fullScreen)}
      >
        {passed > 0 && (
          <Alert severity="success">
            <p className="text-m font-bold">{passed} passed</p>
          </Alert>
        )}
        {failed > 0 && (
          <Alert severity="error">
            <p className="text-m font-bold">{failed} failed</p>
          </Alert>
        )}
      </div>

      <Modal
        open={fullScreen}
        onClose={() => setFullScreen(false)}
        className="flex items-center justify-center"
      >
        <div className="bg-white p-l rounded w-1/3 m-auto self-center flex flex-col gap-m max-h-[75%] overflow-scroll">
          <Title>Playwright Results</Title>
          {result.results?.map((r) => (
            <Alert
              key={r.label}
              severity={r.value === "true" ? "success" : "error"}
            >
              <p>{r.label}</p>
              <p>{r.value}</p>
            </Alert>
          ))}
        </div>
      </Modal>
    </div>
  );
};

const HtmlResult = (result: testTypes.TestResult & { testId: string }) => {
  const src = `/api/test/${result.testId}/result/${result.label}`;

  return (
    <a href={src} target="_blank" rel="noreferrer">
      <Assessment className="!text-brand" />
    </a>
  );
};

const TestResultFactory = (
  result: testTypes.TestResult & { testId: string }
) => {
  switch (result.type) {
    case "image":
      return <ImageResult {...result} />;
    case "number":
      return <NumberResult {...result} />;
    case "percentage":
      return <PercentageResult {...result} />;
    case "playwright-result":
      return <PlaywrightResult {...result} />;
    case "html":
      return <HtmlResult {...result} />;
    case "error":
      return (
        <Alert severity="error">
          <Tooltip title={result.value}>
            <p>{result.value.toString().slice(0, 30)}...</p>
          </Tooltip>
        </Alert>
      );
    default:
      return (
        <p>
          {result.label}: {result.value}
        </p>
      );
  }
};

const processPlaywrightResults = (playwrightTest: testTypes.Test) => {
  const passed =
    playwrightTest.results?.filter((r) => r.value === "true").length ?? 0;
  const failed =
    playwrightTest.results?.filter((r) => r.value === "false").length ?? 0;
  return {
    ...playwrightTest,
    results: [
      {
        label: "Playwright Results",
        type: "playwright-result",
        value: `${passed}/${failed}`,
        results: playwrightTest.results?.filter((r) => r.type === "playwright-result"),
      },
      ...(playwrightTest.results?.filter((r) => r.type !== "playwright-result") ?? []),
    ],
  } as testTypes.Test
};

const TestTeaser = (test: testTypes.Test) => {
  const statusClasses = useMemo(
    () =>
      test.status === "failed" || test.results?.some((r) => r.type === "error")
        ? "!fill-status-error !text-status-error"
        : test.status === "finished"
          ? "!fill-status-success !text-status-success"
          : test.status === "running"
            ? "!fill-status-warning !text-status-warning"
            : "!fill-neutral !text-status-neutral",
    [test.status, test.results]
  );

  const processedTest = useMemo(() => {
    if (test.type === "playwright") {
      return processPlaywrightResults(test);
    }
    return test;
  }, [test]);

  const testInfo = useMemo(() => {
    if (!processedTest.duration){
      return undefined
    }

    switch (processedTest.status) {
      case "failed":
        return `after ${DurationString((processedTest.duration)/1000)}`;
      default:
        return `in ${DurationString((processedTest.duration)/1000)}`;
    }
  }, [processedTest])

  return (
    <Teaser
      icon={<TestIcon className={statusClasses} type={processedTest.type} />}
      title={processedTest.label}
      type={processedTest.type}
      status={processedTest.status}
      info={testInfo}
      statusIcon={<TestStatusIcon status={processedTest.status} />}
      statusColorClass={statusClasses}
      actions={
        <>
          {processedTest.results?.map((result: testTypes.TestResult) => (
            <TestResultFactory
              key={result.label}
              {...result}
              testId={test.id}
            />
          ))}
        </>
      }
    />
  );
};

export { TestTeaser };
